import React from "react"
import { ThemeProvider as Provider } from "emotion-theming"

const Theme = {
  bp: {
    mobile: "@media only screen and (min-width:250px) and (max-width: 424px)",
    tablet: "@media only screen and (min-width:425px)",
    desktop: "@media only screen and (min-width: 768px)",
    largeDesktop: "@media only screen and (min-width: 1200px)",
    hd: "@media only screen and (min-width: 1920px)",
  },
  colors: {
    dark: "black",
    mainBackground: "#eeebeb",
  },
}

export default Theme

export const ThemeProvider = ({ children }) => (
  <Provider theme={Theme}>{children}</Provider>
)
