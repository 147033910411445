import React from "react"
import { ThemeProvider } from "./src/theme"
import { StoreProvider } from "./src/context/store-context"
import { InterfaceProvider } from "./src/context/interface-context"

export const wrapRootElement = ({ element }) => {
  return (
    <InterfaceProvider>
      <ThemeProvider>
        <StoreProvider>{element}</StoreProvider>
      </ThemeProvider>
    </InterfaceProvider>
  )
}
