import React, { useReducer } from "react"

export const defaultInterfaceContext = {
  isCartOpen: false,
  isMenuOpen: false,
  showMenu: () => {},
  hideMenu: () => {},
  showCart: () => {},
  hideCart: () => {},
  toggleCart: () => {},
}

const InterfaceContext = React.createContext(defaultInterfaceContext)

export default InterfaceContext

const reducer = (state, action) => {
  switch (action.type) {
    case "showCart":
      return {
        ...state,
        isCartOpen: true,
      }

    case "hideCart":
      return {
        ...state,
        isCartOpen: false,
      }

    case "showMenu":
      return {
        ...state,
        isMenuOpen: true,
      }

    case "hideMenu":
      return {
        ...state,
        isMenuOpen: false,
      }

    case "toggleCart":
      return {
        ...state,
        isCartOpen: !state.isCartOpen,
      }

    default:
      return state
  }
}

export const InterfaceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultInterfaceContext)

  const showMenu = () => {
    dispatch({ type: "showMenu" })
  }

  const hideMenu = () => {
    dispatch({ type: "hideMenu" })
  }

  const showCart = () => {
    dispatch({ type: "showCart" })
  }

  const hideCart = () => {
    dispatch({ type: "hideCart" })
  }

  const toggleCart = () => {
    dispatch({ type: "toggleCart" })
  }

  return (
    <InterfaceContext.Provider
      value={{
        ...state,
        showCart,
        hideCart,
        showMenu,
        hideMenu,
        toggleCart,
      }}
    >
      {children}
    </InterfaceContext.Provider>
  )
}
