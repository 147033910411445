// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-checkout-done-js": () => import("./../../../src/pages/checkout/done.js" /* webpackChunkName: "component---src-pages-checkout-done-js" */),
  "component---src-pages-checkout-index-js": () => import("./../../../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-checkout-payment-js": () => import("./../../../src/pages/checkout/payment.js" /* webpackChunkName: "component---src-pages-checkout-payment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-grid-js": () => import("./../../../src/templates/product-grid.js" /* webpackChunkName: "component---src-templates-product-grid-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-product-special-js": () => import("./../../../src/templates/product-special.js" /* webpackChunkName: "component---src-templates-product-special-js" */)
}

