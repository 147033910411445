import qs from "query-string"
import React, { useEffect, useReducer } from "react"
import Medusa from "../services/api"

export const defaultStoreContext = {
  adding: false,
  cart: { items: [], currencyCode: "DKK" },
  regions: [],
  addVariantToCart: () => {},
  addCouponCode: () => {},
  removeCouponCode: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
}

const StoreContext = React.createContext(defaultStoreContext)
export default StoreContext

const setCart = (cart) => {
  if (cart.customer_id) {
    if (window.analytics) {
      window.analytics.identify(cart.customer_id)
    }
  }

  return {
    id: cart.id,
    loading: false,
    taxRate: parseFloat(cart.region.tax_rate),
    countryCode: cart.shipping_address && cart.shipping_address.country_code,
    currencyCode: cart.region.currency_code,
    items: cart.items,
    total: cart.total,
    discounts: cart.discounts,
    taxTotal: cart.tax_total || 0,
    subtotal: cart.subtotal || 0,
    shippingTotal: cart.shipping_total || 0,
    region: cart.region,
    discountTotal: cart.discount_total || 0,
    freeShippingLimit: cart.freeShippingLimit,
    metadata: cart.metadata,
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case "setCart":
      if (!action.payload) {
        localStorage.removeItem("medusa::cache")
        return {
          ...state,
          cart: { items: [], currencyCode: "DKK" },
        }
      }

      if (localStorage) {
        localStorage.setItem("medusa::cache", JSON.stringify(action.payload))
      }

      return {
        ...state,
        cart: setCart(action.payload),
      }
    case "setRegions":
      if (!action.payload) {
        localStorage.removeItem("medusa::cache::regions")
        return {
          ...state,
          regions: [],
        }
      }

      if (localStorage) {
        localStorage.setItem(
          "medusa::cache::regions",
          JSON.stringify(action.payload)
        )
      }

      return {
        ...state,
        regions: action.payload,
      }
    default:
      return state
  }
}

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultStoreContext)

  const refreshCart = async (cartId) => {
    if (cartId) {
      return Medusa.cart
        .retrieve(cartId)
        .then(({ data }) => {
          dispatch({ type: "setCart", payload: data.cart })
        })
        .catch((err) => {
          return Medusa.cart.create().then(({ data }) => {
            dispatch({ type: "setCart", payload: data.cart })
          })
        })
    } else {
      if (state.cart.id) {
        return Medusa.cart
          .retrieve(state.cart.id)
          .then(({ data }) => {
            dispatch({ type: "setCart", payload: data.cart })
          })
          .catch((err) => {
            return Medusa.cart.create().then(({ data }) => {
              dispatch({ type: "setCart", payload: data.cart })
            })
          })
      }
    }
  }

  const createCart = async () => {
    // Check shipping options cache and/or create it
    return Medusa.cart
      .create({ country_code: "dk", region_id: "5fb7b54a2b7c94bbc92d4448" })
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
      })
  }

  useEffect(() => {
    const isPGCleared = localStorage.getItem("pg_clear")
    if (!isPGCleared) {
      localStorage.setItem("pg_clear", "t")
      createCart()
    } else {
      const temp = qs.parse(window.location.search)
      if (temp.ct) {
        refreshCart(temp.ct)
      } else {
        if (localStorage) {
          const cachedCart = localStorage.getItem("medusa::cache")
          if (cachedCart) {
            const cart = JSON.parse(cachedCart)
            if (cart.metadata && cart.metadata.is_done) {
              createCart()
            } else {
              dispatch({ type: "setCart", payload: cart })
              refreshCart()
            }
          } else {
            createCart()
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    if (localStorage) {
      const cachedRegions = localStorage.getItem("medusa::cache::regions")
      if (cachedRegions) {
        const regions = JSON.parse(cachedRegions)
        dispatch({ type: "setRegions", payload: regions })
      } else {
        Medusa.regions.list().then(({ data }) => {
          dispatch({ type: "setRegions", payload: data.regions })
        })
      }
    }
  }, [])

  const addVariantToCart = ({ variantId, quantity, metadata }) => {
    return Medusa.cart.lineItems
      .create(state.cart.id, {
        variant_id: variantId,
        quantity,
        metadata,
      })
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const removeLineItem = (lineId) => {
    return Medusa.cart.lineItems
      .delete(state.cart.id, lineId)
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const updateLineItem = (lineId, quantity) => {
    return Medusa.cart.lineItems
      .update(state.cart.id, lineId, { quantity })
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const setRegion = (regionId, countryCode) => {
    return Medusa.cart
      .update(state.cart.id, {
        region_id: regionId,
        country_code: countryCode,
      })
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const removeCouponCode = (couponCode) => {
    return Medusa.cart.discounts
      .delete(state.cart.id, couponCode)
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const addCouponCode = (couponCode) => {
    return Medusa.cart
      .update(state.cart.id, {
        discounts: [{ code: couponCode }],
      })
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const addShippingMethod = (payload) => {
    return Medusa.cart
      .setShippingMethod(state.cart.id, payload)
      .then(({ data }) => {
        dispatch({ type: "setCart", payload: data.cart })
        return data
      })
  }

  const clearCart = () => {
    createCart()
  }

  return (
    <StoreContext.Provider
      value={{
        ...state,
        addShippingMethod,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        addCouponCode,
        removeCouponCode,
        setRegion,
        clearCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
